import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import { useTelegram } from '../../hooks/useTelegram'
import './ModelForm.css'

interface IRegion {
    id: string,
    name: string
}

interface IShop {
    shopBinding: string
    shopName: string
    shopRegion: IRegion
    shopCity: string
    shopStreet: string
    shopHouse: string
    shopEmail: string
    dataType: string
    queryId: string | undefined
    chatId: number | undefined
}

const defaultShop: IShop = {
    shopBinding: '',
    shopName: '',
    shopRegion: { id: '', name: '' },
    shopCity: '',
    shopStreet: '',
    shopHouse: '',
    shopEmail: '',
    dataType: 'shop',
    queryId: undefined,
    chatId: undefined
}

const ModelForm = () => {
    const [shop, setShop] = useState<IShop>(defaultShop)
    const { tg, queryId, user, chat } = useTelegram()

    const [search, setSearch] = useSearchParams()
    const isExtended = search.get('isExtended')

    const regions: any = require('../../data/russia-regions.json')

    const checkData = (): boolean => {
        if (shop.shopName.trim().length < 3) return false
        if (shop.shopCity.trim().length < 2) return false
        if (shop.shopStreet.trim().length < 2) return false
        if (shop.shopHouse.trim().length < 1) return false
        if (!shop.shopRegion.id) return false
        if (!shop.shopEmail.match(/^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/)) return false
        return true
    }

    const onSendData = useCallback(() => {
        tg.sendData(JSON.stringify(shop))
    }, [shop])

    useEffect(() => {
        setShop({ ...shop, shopRegion: { id: regions[0]['iso_3166-2'], name: regions[0].fullname } })
        tg.MainButton.setParams({
            text: 'Отправить данные'
        })
    }, [])

    useEffect(() => {
        tg.onEvent('mainButtonClicked', onSendData)
        return () => {
            tg.offEvent('mainButtonClicked', onSendData)
        }
    }, [onSendData])

    useEffect(() => {
        if (!checkData()) {
            tg.MainButton.hide();
        } else {
            tg.MainButton.show();
        }
    }, [shop])

    function onChangeShopName(e: { target: { value: any } }) {
        setShop({ ...shop, shopName: e.target.value })
    }

    function onChangeShopRegion(e: { target: { value: any } }) {
        const region = regions.filter((r: { [x: string]: any }) => r['iso_3166-2'] === e.target.value)[0]
        setShop({ ...shop, shopRegion: { id: region['iso_3166-2'], name: region.fullname } })
    }

    function onChangeShopCity(e: { target: { value: any } }) {
        setShop({ ...shop, shopCity: e.target.value })
    }

    function onChangeShopStreet(e: { target: { value: any } }) {
        setShop({ ...shop, shopStreet: e.target.value })
    }

    function onChangeShopHouse(e: { target: { value: any } }) {
        setShop({ ...shop, shopHouse: e.target.value })
    }

    function onChangeShopEmail(e: { target: { value: any } }) {
        setShop({ ...shop, shopEmail: e.target.value })
    }

    function onChangeShopBinding(e: { target: { value: any } }) {
        setShop({ ...shop, shopBinding: e.target.value })
    }

    return (
        <div className={"form"}>
            <h3>Введите ваши данные</h3>
            {isExtended === 'true' &&
                <input
                    className={'input'}
                    type="text"
                    placeholder={'Привязать к магазину'}
                    value={shop.shopBinding}
                    onChange={onChangeShopBinding}
                />
            }
            <input
                className={'input'}
                type="text"
                placeholder={'Название магазина'}
                value={shop.shopName}
                onChange={onChangeShopName}
            />
            <select value={shop.shopRegion.id} onChange={onChangeShopRegion} className={'select'}>
                {regions.map((r: { 'iso_3166-2': string, fullname: string }) => (
                    <option key={r['iso_3166-2']} value={r['iso_3166-2']}>{r.fullname}</option>
                ))}
            </select>
            <div className={'address'}>
                <input
                    className={'input'}
                    type="text"
                    placeholder={'Город'}
                    value={shop.shopCity}
                    onChange={onChangeShopCity}
                />
                <input
                    className={'input'}
                    type="text"
                    placeholder={'Улица'}
                    value={shop.shopStreet}
                    onChange={onChangeShopStreet}
                />
                <input
                    className={'input'}
                    type="text"
                    placeholder={'Дом'}
                    value={shop.shopHouse}
                    onChange={onChangeShopHouse}
                />
            </div>
            <input
                className={'input'}
                type="text"
                placeholder={'Email ответственного лица'}
                value={shop.shopEmail}
                onChange={onChangeShopEmail}
            />
        </div>
    );
};

export default ModelForm;